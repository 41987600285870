// **********************************************      CORES     ****************************************************************

//NOMES DAS CORES
export const Lista_cores = [
  "vermelho",
  "verde",
  "azul",
  "rosa",
  "roxo",
  "amarelo",
  "laranja",
];
export const Lista_cores_pasteis = [
  "vermelho pastel",
  "verde pastel",
  "azul pastel",
  "rosa pastel",
  "roxo pastel",
  "amarelo pastel",
  "laranja pastel",
  "marrom pastel",
];

// CORES EM EXADECIMAL, TAMBÉM É O VALOR PADRÃO DAS CORES
export const Lista_cores_exa = [
  "#FF0000", //vermelho
  "#00FF00", //verde
  "#0000FF", //azul
  "#FF1493", //rosa
  "#8B008B", //roxo
  "#ffeb00fa", //amarelo
  "#FF8C00", //laranja
];
export const Lista_cores_exa_pasteis = [
  "#850000", //vermelho
  "#82f874", //verde
  "#b3edeb", //azul
  "#ffad7e", //rosa
  "#b1557c", //roxo
  "#eacf43", //amarelo
  "#e36615", //laranja
  "#704a02", //marrom
];

export const Tema = (tema) => {
  switch (tema) {
    case "escuro":
      return "dark";
    case "claro":
      return "light";
    default:
      return "dark";
  }
};

export const CorSecundaria = (cor) => {
  switch (cor) {
    case "preto":
      return {
        light: "#ff7961",
        main: "#000000",
        dark: "#ba000d",
        contrastText: "#fff",
      };
    case "branco":
      return {
        light: "#ff7961",
        main: "#FFFFFF",
        dark: "#ba000d",
        contrastText: "#000",
      };
    default:
      return {
        light: "#ff7961",
        main: "#000000",
        dark: "#ba000d",
        contrastText: "#fff",
      };
  }
};

const Cores = (cor) => {
  switch (cor) {
    case "vermelho":
      return {
        light: "#FF2222",
        main: Lista_cores_exa[0],
        dark: "#DA1212",
        contrastText: "#fff",
        nome: Lista_cores[0],
      };
    case "verde":
      return {
        light: "#7FFF00",
        main: Lista_cores_exa[1],
        dark: "#006400",
        contrastText: "#000",
        nome: Lista_cores[1],
      };
    case "azul":
      return {
        light: "#0069FF",
        main: Lista_cores_exa[2],
        dark: "#00008C",
        contrastText: "#fff",
        nome: Lista_cores[2],
      };
    case "rosa":
      return {
        light: "#FF439E",
        main: Lista_cores_exa[3],
        dark: "#FF009E",
        contrastText: "#fff",
        nome: Lista_cores[3],
      };
    case "roxo":
      return {
        light: "#ED0098",
        main: Lista_cores_exa[4],
        dark: "#8B008B",
        contrastText: "#fff",
        nome: Lista_cores[4],
      };
    case "amarelo":
      return {
        light: "#FFFF00",
        main: Lista_cores_exa[5],
        dark: "#FFBD00",
        contrastText: "#000",
        nome: Lista_cores[5],
      };
    case "laranja":
      return {
        light: "#FF7101",
        main: Lista_cores_exa[6],
        dark: "#FF3A01",
        contrastText: "#fff",
        nome: Lista_cores[6],
      };
    case "vermelho pastel":
      return {
        light: "#FF2222",
        main: Lista_cores_exa_pasteis[0],
        dark: "#DA1212",
        contrastText: "#fff",
        nome: Lista_cores_pasteis[0],
      };
    case "verde pastel":
      return {
        light: "#7FFF00",
        main: Lista_cores_exa_pasteis[1],
        dark: "#006400",
        contrastText: "#000",
        nome: Lista_cores_pasteis[1],
      };
    case "azul pastel":
      return {
        light: "#0069FF",
        main: Lista_cores_exa_pasteis[2],
        dark: "#00008C",
        contrastText: "#000",
        nome: Lista_cores_pasteis[2],
      };
    case "rosa pastel":
      return {
        light: "#FF439E",
        main: Lista_cores_exa_pasteis[3],
        dark: "#FF009E",
        contrastText: "#000",
        nome: Lista_cores_pasteis[3],
      };
    case "roxo pastel":
      return {
        light: "#ED0098",
        main: Lista_cores_exa_pasteis[4],
        dark: "#8B008B",
        contrastText: "#fff",
        nome: Lista_cores_pasteis[4],
      };
    case "amarelo pastel":
      return {
        light: "#FFFF00",
        main: Lista_cores_exa_pasteis[5],
        dark: "#FFBD00",
        contrastText: "#000",
        nome: Lista_cores_pasteis[5],
      };
    case "laranja pastel":
      return {
        light: "#FF7101",
        main: Lista_cores_exa_pasteis[6],
        dark: "#FF3A01",
        contrastText: "#fff",
        nome: Lista_cores_pasteis[6],
      };
      case "marrom pastel":
        return {
          light: "#FF7101",
          main: Lista_cores_exa_pasteis[7],
          dark: "#FF3A01",
          contrastText: "#fff",
          nome: Lista_cores_pasteis[7],
        };
    default:
      return {
        light: "#FF2222",
        main: Lista_cores_exa[0],
        dark: "#DA1212",
        contrastText: "#000",
        nome: Lista_cores[0],
      };
  }
};

export default Cores;
