import ShopIcon from '@mui/icons-material/Shop';
import CallIcon from '@mui/icons-material/Call';
import LoginIcon from '@mui/icons-material/Login';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FacebookIcon from '@mui/icons-material/Facebook';
import BusinessIcon from '@mui/icons-material/Business';
import InstagramIcon from '@mui/icons-material/Instagram';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';

export const icone = {
    whatsapp: <WhatsAppIcon color='primary' />,
    facebook: <FacebookOutlinedIcon color='primary' />,
    instagram: <InstagramIcon color='primary' />,
    google: <GoogleIcon color='primary' />,
    inicio: <HomeOutlinedIcon color='primary' />,
    grafico: <EqualizerOutlinedIcon color='primary' />,
    parceiros: <GroupsOutlinedIcon color='primary' />,
    novidade: <AutoAwesomeIcon color='primary' />,
    produtos: <ShoppingCartIcon color='primary' />,
    personalizacao: <AppRegistrationIcon color='primary' />,
    cor: <ColorLensIcon color='primary' />,
    app: <AppShortcutIcon color='primary' />,
    negocio: <BusinessIcon color='primary' />,
    direita: <ChevronRightIcon color='primary' />,
    login: <LoginIcon color='primary' />,
    playstore: <ShopIcon color='primary' />,
    trespontinhos: <MoreVertIcon color='primary' />,
    conectado: <NetworkCheckIcon color='primary' />,
    desconectado: <SignalWifiConnectedNoInternet4Icon color='primary' />,
    telefone: <CallIcon color='primary'/>,
    cadastro: <AssignmentIndIcon color='primary'/>,
    adminstrador: <AdminPanelSettingsIcon color='primary' alt="Administrador"/>,
    usuario: <PersonIcon color='info' alt="Usuário"/>,
    email: <EmailIcon color='primary' alt="E-mail"/>,
    sair: <LogoutIcon color='warning' alt="Sair"/>,
    deslogado: <PhonelinkEraseIcon color='error' alt="Deslogado" />,
    logado: <SystemSecurityUpdateGoodIcon color='primary' alt="Logado"/>,
    remover: <RemoveCircleOutlinedIcon color='error' alt="Remover"/>
    
};