import * as React from "react";
import Box from "@mui/material/Box";
import Cores, {
  Lista_cores,
  Lista_cores_exa,
  Lista_cores_exa_pasteis,
  Lista_cores_pasteis,
} from "../../config/cores";
import { Divider, Paper, Radio, Typography } from "@mui/material";
import { TrocaCor } from "../../store/action";
import { connect, useDispatch } from "react-redux";
import { Icone_default_cor } from "../icones/icones";
import { DBcor } from "../../config/login/valida";
import { Cores_pasteis } from "./../../config/cores";

function BarraCores({ cor, email }) {
  const dispatch = useDispatch();
  const Troca = (event) => {
    DBcor(email, event.target.value);
    dispatch(TrocaCor(Cores(event.target.value)));
  };
  const controlProps = (item) => ({
    checked: cor.nome === item,
    onChange: Troca,
    value: item,
    name: "Cores_site",
    inputProps: { "aria-label": item },
  });

  return (
    <div>
      <Paper elevation={1} sx={{ p: 4 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          align="center"
          margin={0}
          sx={{ color: Cores(cor.nome).main }}
        >
          Escolha uma cor
        </Typography>
        <Box
          sx={{
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          {Lista_cores.map((item, index) => (
            <Radio
              {...controlProps(item)}
              key={index}
              sx={{ color: Lista_cores_exa[index] }}
            />
          ))}
        </Box>
        <br />
        <Divider />
        <br />
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          align="center"
          margin={0}
          sx={{ color: Cores(cor.nome).main }}
        >
          Escolha uma cor pastel
        </Typography>
        <Box
          sx={{
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          {Lista_cores_pasteis.map((item, index) => (
            <Radio
              {...controlProps(item)}
              key={index}
              sx={{ color: Lista_cores_exa_pasteis[index] }}
            />
          ))}
        </Box>
      </Paper>
    </div>
  );
}

export default connect((state) => ({
  cor: state.cor,
  email: state.usuarioGoogle.email,
}))(BarraCores);
