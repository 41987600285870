import Cores, { CorSecundaria, Tema } from "./../config/cores";
import { ConverteDataBanco, DataAtualSegundosIso } from './../config/mascaras/mascaras';
const dataAtualIso = DataAtualSegundosIso();
export const inicioGoogle = {
  nome: "",
  email: "",
  telefone: "",
  id: "",
  foto: "",
  anonimo: "",
  emailverificado: "",
  token: "",
};
export const inicioBanco = {
  id: "",
  nome: "",
  email: "",
  foto: "",
  chave: "",
  logado: "",
  tema: "",
  tipo: "",
  cor: "",
  telefone: "",
  apelido: "",
  nascimento: "",
  senha: "",
  cep: "",
  logradouro: "",
  complemento: "",
  numero: "",
  cidade: "",
  bairro: "",
  estado: "SP",
  modificacao: "",
  criacao: "",
  notificacao: {seconds: dataAtualIso}
};
const alertaInicial = {
  ativo: false,
  codigo: "",
  mensagem: "",
  tipo: "",
  tempo: "",
};
export const usuariosBancoInicio = [
  {
    nome: "Nenhum usuario encontrado",
    apelido: "",
    telefone: "",
    email: "",
    foto: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    chave: "",
    cor: "",
    nascimento: "",
    senha: "",
    tema: "",
    tipo: false,
    logado: false,
    modificacao: "",
    criacao: "",
    notificacao: {seconds: dataAtualIso}
  },
];
export const paginaAlertaInicio = {
    estado: false,
  }

  export const inicioTransacoes = {
    contador: 0
  }
export const listaTransacoesInicio = [
  {
    titulo:"Nenhuma transação recebida",
    descricao:"",
    ordem:"",
    usuario:"",
    valor:"",
    status:"",
    criacao: ConverteDataBanco(),
    metodo:"",
    local:"",
    identificador:"",
    tipo:""
  },
];
export const inicioListaNotificacao = [
  {
    titulo: "Nenhuma nova notificação",
    descricao:"",
    data:{seconds: dataAtualIso} 
  }
]
export const status_inicial = {
  cor: Cores("vermelho"),
  cor_secundaria: CorSecundaria("preto"),
  tema: Tema("escuro"),
  usuario: "",
  logado: false,
  usuarioGoogle: inicioGoogle,
  banco: inicioBanco,
  master: false,
  alerta: alertaInicial,
  usuariosBanco: usuariosBancoInicio,
  listaTransacoes: listaTransacoesInicio,
  paginaAlerta: paginaAlertaInicio, 
  bancoTransacoes: inicioTransacoes,
  listaNotificacao: inicioListaNotificacao,
  numeroNotificacoes: 0
};

export const log_priori = {
  nome: "Stevie Kelvin",
  id: "gYdcTcMmeye7fMMFkDorAO3QvcX2",
};

