import { Avisos } from "../avisos";
import { atualizaBanco } from "../api/firebase";
import { MascaraDataAtual } from "../mascaras/mascaras";
import { AlertaMensagem } from './../../store/action';
import store from './../../store/store';

export const DBmaster = async (id) => {
    const data = {modificacao: MascaraDataAtual('completa', '')};
    const master = {tipo: true};
    const logado = {logado: true};
    atualizaBanco(master, id);
    atualizaBanco(logado, id);
    atualizaBanco(data, id);
}
export const DBslave = async (id) => {
    const data = {modificacao: MascaraDataAtual('completa', '')};
    const slave = {tipo: false};
    const logado = {logado: true};
    atualizaBanco(slave, id);
    atualizaBanco(logado, id);
    atualizaBanco(data, id);
}
export const DBdesloga = async (id) => {
    const data = { modificacao: MascaraDataAtual('completa', '') };
    const deslogado = {logado: false};
    atualizaBanco(deslogado, id);
    atualizaBanco(data, id);
}
export const DBcor = async (id, nome) => {
    const data = { modificacao: MascaraDataAtual('completa', '') };
    const cor_ = {cor: nome};
    atualizaBanco(cor_, id);
    atualizaBanco(data, id);
}
export const DBaltera = async (id, dado) => {
    const data = { modificacao: MascaraDataAtual('completa', '') };
    atualizaBanco(dado, id);
    atualizaBanco(data, id);
    store.dispatch(AlertaMensagem('0013'));
}
export const DBleNotificacao = async (id) => {
    const data = { modificacao: MascaraDataAtual('completa', '') };
    atualizaBanco({notificacao: MascaraDataAtual('completa', '')}, id);
    atualizaBanco(data, id);
    store.dispatch(AlertaMensagem('0013'));
}
