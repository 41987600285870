import React, { useState } from "react";
import { info } from "./../../dados/info";
import { atualizaBanco, BuscaDado, CriaDado, fire } from "./../api/firebase";
import { Button, Avatar } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { log_priori } from "./../../store/valor_inicio";
import { icone } from "./../../components/icones/icones";
import { info_login } from "./../../dados/login/info_login";
import { info_perfil } from "./../../dados/login/info_perfil";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  AlertaMensagem,
  deslogaGoogle,
  LogaGoogle,
  LogaMaster,
} from "../../store/action";
import { DBdesloga, DBmaster, DBslave } from "./valida";

const provider = new GoogleAuthProvider();

export const BotaoGoogle = ({ logado, dados, usr }) => {
  const dispatch = useDispatch();
  const auth = getAuth(fire);

  // Logou com sucesso!!
  const Logou = async (user, token) => {
    const U = await user;

    const data = {
      id: U.uid,
      nome: U.displayName,
      email: U.email,
      foto: U.photoURL,
      chave: U.uid,
      logado: logado,
      tema: dados.tema,
      tipo: dados.master,
      cor: dados.cor.nome,
      telefone: "",
      apelido: U.displayName,
      nascimento: "",
      senha: "",
    };
    const usuarioBanco = BuscaDado(U.email, dispatch);
    usuarioBanco
      .then((res) => {
        if (res !== 0) {
          console.log("Usuario econtrado!");
        } else {
          CriaDado(data);
          console.log(
            "Usuario não encontrado na base de dados!, criando dados!"
          );
          // CRIA DADOS E RETORNA A PESQUISA
          const usuarioBanco = BuscaDado(U.email, dispatch);
          usuarioBanco
            .then((res) => {
              if (res !== 0) {
                console.log("Atualizando novos dados!");
              } else {
                console.log("Não foi possivel criar usuario!");
              }
            })
            .catch((err) => {
              console.log(err);
            });
          //  FIM DA CRIAÇÃO DE DADOS
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(
      LogaGoogle({
        nome: U.displayName,
        email: U.email,
        telefone: U.phoneNumber,
        id: U.uid,
        foto: U.photoURL,
        anonimo: U.isAnonymous,
        emailverificado: U.emailVerified,
        token: token,
      })
    );
    const id_priori = log_priori.id;

    if (U.uid === id_priori) {
      dispatch(LogaMaster(true));
      dispatch(AlertaMensagem("0007"));
      DBmaster(U.email);
    } else {
      dispatch(LogaMaster(false));
      dispatch(AlertaMensagem("0008"));
      DBslave(U.email);
    }
  };

  // Entrar com conta Google
  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = await result.user;
        Logou(user, token);
      })
      .catch((error) => {
        if (error.code !== "undefined") {
          console.log(error.code);
          const errorCode = error.code;
          const credential = GoogleAuthProvider.credentialFromError(error);
          switch (errorCode) {
            case "auth/popup-closed-by-user":
              return dispatch(AlertaMensagem("0009"));
            case "auth/internal-error":
              return dispatch(AlertaMensagem("0010"));
            case "auth/invalid-password":
              return dispatch(AlertaMensagem("0011"));
            case "auth/cancelled-popup-request":
              return dispatch(AlertaMensagem("0012"));
            default:
              return 0;
          }
        }
      });
  };
  //sair da conta Google
  const signOutGoogle = () => {
    DBdesloga(usr);
    signOut(auth)
      .then(() => {
        dispatch(deslogaGoogle());
        dispatch(AlertaMensagem("0005"));

        if (logado === true) {
          dispatch(LogaMaster(false));
        }
      })
      .catch((error) => {
        console.log(error.code);
        return alert("Erro ao Deslogar, tente novamente!")(
          dispatch(AlertaMensagem("0006"))
        );
      });
  };

  return (
    <div>
      {logado === false ? (
        <Button
          type="button"
          fullWidth
          variant="contained"
          onClick={signInGoogle}
          sx={{ mb: 1, mt: 5 }}
        >
          <Avatar sx={{ mr: 3, bgcolor: "secondary.main" }}>
            {" "}
            {icone.google}{" "}
          </Avatar>
          {info_login.botao}
        </Button>
      ) : (
        <Button
          type="button"
          fullWidth
          variant="contained"
          onClick={signOutGoogle}
          sx={{ mb: 1, mt: 5 }}
        >
          <Avatar sx={{ mr: 3, bgcolor: "secondary.main" }}>
            {" "}
            {icone.google}{" "}
          </Avatar>
          {info_perfil.botao}
        </Button>
      )}
    </div>
  );
};

export default connect((state) => ({
  logado: state.logado,
  dados: state,
  usr: state.usuarioGoogle.email,
}))(BotaoGoogle);
