import Pagina_padrao from "../../geral/modelo_pagina";
import { Box } from "@mui/material";
import Cadastro from './cadastro';
import { connect } from 'react-redux';
import { info } from './../../../dados/info';
import { restricao } from './../../../config/restricoes';
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';
import { Navigate } from 'react-router-dom';

const CadastroIndex = ({ logado, master }) => {
  logEvent(analytics, "CADASTRO");
    if (logado !== true ) {
        return <Navigate to="/login" />
   }
  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div>
        <Pagina_padrao
          titulo="Cadastro"
          subtitulo="Suas informações"
          alignItems="center"
        >
       <Cadastro/>
        </Pagina_padrao>
      </div>
    </Box>
  );
};
export default connect((state) => ({ logado: state.banco.logado, master: state.banco.tipo}))(CadastroIndex);