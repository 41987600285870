import { Avisos } from "../config/avisos";
import { inicioGoogle, status_inicial } from "./valor_inicio";

// TROCA DE COR PRIMARIA DO SITE
export const TrocaCor = (cor) => {
  return {
    type: "TROCA_COR",
    cor,
  };
};

// TROCA DE TEMA O SITE
export const TrocaTema = (tema) => {
  return {
    type: "TEMA",
    tema,
  };
};
// SALVA DADOS DO USUARIO VINDO DO BANCO
export const SalvaDadoBanco = (banco) => {
  return{
    type: "USUARIO_BANCO",
    banco
  }
}

// LOGA COM USUARIO GOOGLE TRASENDO TODAS AS INFORMAÇÕES
export const LogaGoogle = (usuarioGoogle) => {
  return {
    type: "USUARIO_GOOGLE",
    usuarioGoogle,
    logado: true,
  };
};

// DESLOGA DO GOOGLE RETORNANDO AS INFORMAÇÕES INICIAIS
export const deslogaGoogle = () => {
  return {
    type: "DESLOGA_GOOGLE",
    usuarioGoogle: inicioGoogle,
    logado: false,
  };
};

// ATIVA O USUARIO MASTER
export const LogaMaster = (master) => {
  return {
    type: "USUARIO_MASTER",
    master
  };
};

// CRIA ALERTA DE MENSAGEM
export const AlertaMensagem = (codigo) => {
  let a = Avisos(codigo);
  return {
    type: "ALERTA",
    alerta: {
      ativo: true,
      codigo: a.code,
      mensagem: a.mensagem,
      tipo: a.tipo,
      tempo: a.tempo
  }
  };
};

// APAGA MENSAGEM
export const LimpaMensagem = () => {
  return {
    type: "LIMPA_MENSAGEM",
    alerta: status_inicial.alerta
  };
};

// SALVAR TODOS OS USUARIOS
export const SalvaUsuarios = (usuariosBanco) => {
  return{
    type: "TODOS_USUARIOS",
    usuariosBanco
  }
}
// SALVAR TODAS AS TRANSACOES
export const SalvaTransacoes = (listaTransacoes) => {
  return{
    type: "TODAS_TRANSACOES",
    listaTransacoes
  }
}
// SALVAR TODAS AS NOTIFICACOES
export const SalvaNotificacoes = (listaNotificacao) => {
  return{
    type: "TODAS_NOTIFICACOES",
    listaNotificacao
  }
}
// TROCA VALORES DE PAGINA ALERTA
export const ValorPaginaAlerta = (paginaAlerta) => {
  return{
    type: "PAGINA_ALERTA",
    paginaAlerta
  }
}
// ATUALIZA CONTADOR BANCO TRANSACOES
export const UltimaTransacao = (bancoTransacoes) => {
  return{
    type: "ULTIMA_TRANSACAO",
    bancoTransacoes
  }
}
// ATUALIZA NUMERO DE NOTIFICAÇÕES NÃO LIDAS
export const NumNotificacoes = (numeroNotificacoes) => {
  return{
    type: "NUMERO_NOTIFICACOES",
    numeroNotificacoes
  }
}