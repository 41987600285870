
import  Box  from '@mui/material/Box';
import { Typography } from '@mui/material';
import Copyright from './copyright';
import { info } from './../../dados/info';


const Rodape = () => {
    return ( 
        <div>
             {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', mt: 10}} pt={{ xs: 3 }} component="footer" >
        <Typography variant="h6" align="center" color='primary'>
          {info[0].titulo}
        </Typography>
        <Typography variant="subtitle2" align="center" gutterBottom>
          {info[0].subtitulo}
        </Typography>
        <Typography variant="subtitle2" color='primary' align="center" gutterBottom>
          Desenvolvedor: {info[0].desenvolvedor}
        </Typography>
        <br/> 
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Todos os direitos reservados
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
        </div>
     );
}
 
export default Rodape;