import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Titulo from '../titulo/titulo';
import { grafico_recebidos_mes } from '../../../../dados/grafico/recebido';
import { SomaArray } from './../../../../config/func';
import store from './../../../../store/store';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MascaraDataAtual, MascaraReais, Mascara_mes_por_escrito } from './../../../../config/mascaras/mascaras';
import PaginaAlerta from '../../../geral/descricaoOrdens';
import Tabela from './../../../geral/tabela';

function preventDefault(event) {
  event.preventDefault();
}

function Transferencias ({valores}) {

  return (
    <React.Fragment>
      <Titulo>Entradas Recentes</Titulo>
      <Typography component="p" variant="h4">
        {MascaraReais(valores.map((item) => Number(item.valor)).reduce((prev, curr) =>  (prev + curr) , 0))}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        até {Mascara_mes_por_escrito()}
      </Typography>
      <div>
      {/* <PaginaAlerta titulo={'Balanço Mensal'} conteudo={""} botao={"Ver Balanço"} /> */}
      </div>
    </React.Fragment>
  );
}

export default connect((state) => ({ valores: state.listaTransacoes }))(
  Transferencias
);
