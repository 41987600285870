import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Pagina_padrao(props) {
  return (
 <div>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6
            
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              sx={{pt: 5}}
              color="text.primary"

              gutterBottom
            >
            {props.titulo}
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              {props.subtitulo}
            </Typography>
          
          </Container>
        
        <Container  sx={{ pt: 10 }}>
          {(props.manutencao === 'sim') ? (
          <Box>
             <Typography
              component="h1"
              variant="h4"
              align="center"
              sx={{pt: 5, pb: 10}}
              color="text.primary"

              gutterBottom
            >
              Pagina em Manutênção,
              <br/> 
              por favor aguarde!!
              </Typography>
          </Box>
          ): (props.children)}
    
        </Container>
        </Box>
      </main>
 
      </div>
  );
}