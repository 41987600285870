import * as React from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { info } from "./../../dados/info";
import { icone } from "./../icones/icones";
import Notificacao from "./../geral/alerta";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Conteudo from "./../conteudo/conteudo";
import Inicio from "./../paginas/inicio/inicio";
import MenuIcon from "@mui/icons-material/Menu";
import { screen } from "@testing-library/react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import InfoIndex from "./../paginas/info/infoIndex";
import LoginIndex from "../paginas/login/loginIndex";
import Parceiros from "../paginas/parceiros/parceiros";
import { novidades } from "../../dados/novidades/novidades";
import CadastroIndex from "../paginas/cadastro/cadastroIndex";
import GraficoIndex from "./../paginas/grafico/graficoIndex";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Personalizacao from "../paginas/personalizacao/personalizacao";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { logEvent } from "firebase/analytics";
import { analytics } from "./../../config/api/firebase";
import store from "../../store/store";
import { MascaraDiferencaDiasBanco } from "../../config/mascaras/mascaras";
import { NumNotificacoes } from "../../store/action";

// CRIADOR DE ROTAS E DE MENU LATERAL ESQUERDO
export const pagina = [
  {
    titulo: "Início",
    rota: "/",
    pagina: <Inicio />,
    menu: "sim",
    mobile: "sim",
    icone: icone.inicio,
  },
  {
    titulo: "Parceiros",
    rota: "parceiros/",
    pagina: <Parceiros />,
    menu: "sim",
    mobile: "sim",
    icone: icone.parceiros,
  },
  {
    titulo: "Personalização",
    rota: "personalizacao/",
    pagina: <Personalizacao />,
    menu: "sim",
    mobile: "sim",
    icone: icone.personalizacao,
  },
  {
    titulo: "Informações",
    rota: "informacoes/",
    pagina: <InfoIndex />,
    menu: "não",
    mobile: "não",
    icone: icone.novidade,
  },
  {
    titulo: "Login",
    rota: "login/",
    pagina: <LoginIndex />,
    menu: "sim",
    mobile: "sim",
    icone: icone.login,
  },
  {
    titulo: "Cadastro",
    rota: "cadastro/",
    pagina: <CadastroIndex />,
    menu: "sim",
    mobile: "sim",
    icone: icone.cadastro,
  },
];

export const item_priori = [
  {
    titulo: "Grafico",
    rota: "grafico/",
    pagina: <GraficoIndex />,
    menu: "sim",
    mobile: "sim",
    icone: icone.grafico,
  },
];

const Corpo = ({ cor, dados }) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  React.useEffect(() => {
    dados.listaNotificacao.map((item, index) => {
      if (
        MascaraDiferencaDiasBanco(
          item.data.seconds,
          dados.banco.notificacao.seconds
        ) > 1
      ) {
        let num = store.getState().numeroNotificacoes;
        store.dispatch(NumNotificacoes(num + 1));
      }
    });
  }, [dados.listaNotificacao]);

  const drawerWidth = 200;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "fixed",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Notificacao />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            backgroundColor: "primary.main",
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="secondary"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <Tooltip title={"Abrir menu"} placement="right">
              <MenuIcon />
            </Tooltip>
          </IconButton>
          <Typography
            align="center"
            component="h1"
            variant="h5"
            color={cor.contrastText}
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {info[0].titulo}
          </Typography>
          <IconButton color="inherit" component={Link} to="informacoes">
            <Badge
              badgeContent={dados.listaNotificacao.length} //PASSANDO O VALOR DO TOTAL DE NOTIFICAÇÕES 
              color="secondary"
            >
              <Tooltip title={"Informações"} placement="right">
                <NotificationsIcon color="secondary"></NotificationsIcon>
              </Tooltip>
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer} color="primary">
            <Tooltip title={"Fechar menu"} placement="right">
              <ChevronLeftIcon />
            </Tooltip>
          </IconButton>
        </Toolbar>

        <List component="nav">
          {pagina.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: {
                    xs:
                      item.menu === "sim" && item.mobile === "sim"
                        ? "block"
                        : "none", // theme.breakpoints.up('xs')
                    sm:
                      item.menu === "sim" && item.mobile === "sim"
                        ? "block"
                        : "none", // theme.breakpoints.up('sm')
                    md:
                      item.menu === "sim" && item.mobile === "sim"
                        ? "block"
                        : "none", // theme.breakpoints.up('md')
                    lg: item.menu === "sim" ? "block" : "none", // theme.breakpoints.up('lg')
                    xl: item.menu === "sim" ? "block" : "none", // theme.breakpoints.up('xl')
                  },
                }}
              >
                <Tooltip title={item.titulo} placement="right">
                  <ListItemButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    component={Link}
                    to={item.rota}
                  >
                    <ListItemIcon>{item.icone}</ListItemIcon>
                    <ListItemText primary={item.titulo} />
                  </ListItemButton>
                </Tooltip>
              </Box>
            </React.Fragment>
          ))}
          <React.Fragment>
            <Tooltip title={"Produtos"} placement="right">
              <ListItemButton
                onClick={() => {
                  window.open(
                    "https://www.magazinevoce.com.br/magazinetalentonatocom",
                    "_blank"
                  );
                  logEvent(analytics, "LOJINHA_EXTERNA");
                }}
              >
                <ListItemIcon>{icone.produtos}</ListItemIcon>
                <ListItemText primary="Produtos" />
              </ListItemButton>
            </Tooltip>
          </React.Fragment>

          {item_priori.map((item, index) => (
            <Box
              sx={{ display: dados.banco.tipo === true ? "block" : "none" }}
              key={index}
            >
              <React.Fragment>
                <Box
                  sx={{
                    display: {
                      xs:
                        item.menu === "sim" && item.mobile === "sim"
                          ? "block"
                          : "none", // theme.breakpoints.up('xs')
                      sm:
                        item.menu === "sim" && item.mobile === "sim"
                          ? "block"
                          : "none", // theme.breakpoints.up('sm')
                      md:
                        item.menu === "sim" && item.mobile === "sim"
                          ? "block"
                          : "none", // theme.breakpoints.up('md')
                      lg: item.menu === "sim" ? "block" : "none", // theme.breakpoints.up('lg')
                      xl: item.menu === "sim" ? "block" : "none", // theme.breakpoints.up('xl')
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    component={Link}
                    to={item.rota}
                  >
                    <ListItemIcon>{item.icone}</ListItemIcon>
                    <ListItemText primary={item.titulo} />
                  </ListItemButton>
                </Box>
              </React.Fragment>
            </Box>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          bgcolor: "background.paper",
          flexGrow: 1,
          pl: 5,
          pr: 0,
          height: screen.availHeight,
          width: screen.availWidth,
          overflow: "auto",
        }}
      >
        <Conteudo />
      </Box>
    </Box>
  );
};

export default connect((state) => ({ cor: state.cor, dados: state }))(Corpo);
