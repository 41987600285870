import { useEffect } from "react";
import { connect } from "react-redux";
import { SnackbarProvider } from "notistack";
import Corpo from "./components/corpo/corpo";
import { BuscaNotificacao, RecuperarUsuario } from "./config/api/firebase";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App({ cor_primaria, cor_secundaria, tema, logado }) {
  useEffect(() => {
    RecuperarUsuario();
    BuscaNotificacao();
  }, [logado]);
  const theme = createTheme(
    {
      palette: {
        mode: tema,
        primary: cor_primaria,
        secondary: cor_secundaria,
      },
    },
    [cor_primaria, cor_secundaria, tema]
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SnackbarProvider maxSnack={3}>
          <Corpo />
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  );
}

export default connect((state) => ({
  cor_primaria: state.cor,
  cor_secundaria: state.cor_secundaria,
  tema: state.tema,
  logado: state.logado,
}))(App);
