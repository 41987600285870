import { Box } from "@mui/material";
import { connect } from "react-redux";
import { info } from "../../../dados/info";
import Pagina_padrao from "./../../geral/modelo_pagina";
import Grafico from "./grafico";
import { restricao } from "./../../../config/restricoes";
import { BuscaUsuarios } from "../../../config/api/firebase";
import store from "./../../../store/store";
import { usuariosBancoInicio } from "../../../store/valor_inicio";

const GraficoIndex = ({ logado, master }) => {
  if (1){
    if ((logado !== true) ^ (master !== true)) {
      return (window.location.href = info[0].login);
    } else {
      if (store.getState().usuariosBanco === usuariosBancoInicio) {
        BuscaUsuarios();
      }
    }
  } else {
    if (store.getState().usuariosBanco === usuariosBancoInicio) {
      BuscaUsuarios();
    }
  }
  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div>
        <Pagina_padrao
          titulo="Grafico"
          subtitulo="Tenha em mãos todas as informações necessarias para gerenciar seu empreendimento"
          alignItems="center"
        >
          <Grafico />
        </Pagina_padrao>
      </div>
    </Box>
  );
};

export default connect((state) => ({
  logado: state.banco.logado,
  master: state.banco.tipo,
}))(GraficoIndex);
