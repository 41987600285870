import Pagina_padrao from "../../geral/modelo_pagina";
import Grid from "@mui/material/Grid";
import { Box, styled, Paper } from "@mui/material";
import BarraCores from "../../geral/barraCores";
import SeletorTema from "../../geral/tema";
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Personalizacao = () => {
  logEvent(analytics, "PERSONALIZAÇÃO");
  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div>
        <Pagina_padrao
          titulo="Personalização"
          subtitulo="Escolha o melhor estilo para sua navegação"
          alignItems="center"
        >
          <div>
            <Grid container alignItems="center" spacing={2} sx={{}}>
              <Grid item xs={12} md={6}>
                <Item>
                  <BarraCores />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item>
                  <SeletorTema />
                </Item>
              </Grid>
            </Grid>
          </div>
        </Pagina_padrao>
      </div>
    </Box>
  );
};

export default Personalizacao;
