import Pagina_padrao from "../../geral/modelo_pagina";
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Card_padrao from "../../geral/card_padrao";
import { novidades } from './../../../dados/novidades/novidades';
import Lista from './lista';

const Informacoes = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
            <Grid container alignItems='center' spacing={0}>
            <Lista />
            </Grid>
    </Box>
  );
};

export default Informacoes;
