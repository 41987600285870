import { info } from './../info';
import imgManutencao from '../../components/imagens/manutencao.jpg';
import imgBackup from '../../components/imagens/backup.jpg';
import imgSites from '../../components/imagens/sites.jpg';
import imgSoftwares from '../../components/imagens/softwares.jpg';
import imgUpgrade from '../../components/imagens/upgrade.jpg';
import imgInsternet from '../../components/imagens/internet.jpg';



export const servicos = [
  {
    titulo: "Criação de Sites",
    descricao: "Adquira para seu pequeno ou médio negócio, o seu proprio site personalizado!",
    botao: "Pedir",
    rota: info[0].links.servicos.site,
    imagem: imgSites,
    novidade: 'sim',
    iconeWhatsapp: 'sim'
  },
  // {
  //   titulo: "Internet Rural",
  //   descricao: "Instalamos internet Rural na região de Monteiro Lobato, São Francisco Xavier, Taubaté e Pindaminhangaba - SP, acesse e descubra!",
  //   botao: "Pedir",
  //   rota: info[0].links.servicos.internet,
  //   imagem: imgInsternet,
  //   novidade: 'sim',
  //   iconeWhatsapp: 'sim'
  // },
  {
    titulo: "Upgrade",
    descricao: "Descubra todo poder da sua máquina, fazendo melhorias significativas para seu melhor desempenho",
    botao: "Pedir",
    rota: info[0].links.servicos.upgrade,
    imagem: imgUpgrade,
    novidade: 'sim',
    iconeWhatsapp: 'sim'
  },
  {
    titulo: "Manutenção",
    descricao: "Temos diversas soluções em manutenção para seu Notebook, Tablet, PC.. consulte e faça seu orçamento",
    botao: "Pedir",
    rota: info[0].links.servicos.manutencao,
    imagem: imgManutencao,
    novidade: 'não',
    iconeWhatsapp: 'sim'
  },
  {
    titulo: "Backup",
    descricao: "Mantenha seus dados mais importantes sempre a salvo",
    botao: "Pedir",
    rota: info[0].links.servicos.backup,
    imagem: imgBackup,
    novidade: 'não',
    iconeWhatsapp: 'sim'
  },
  {
    titulo: "Instalação de Softwares",
    descricao: "Instalamos pra você os programas que vão deixar sua vida mais prática, com softwares de sua necessidade",
    botao: "Pedir",
    rota: info[0].links.servicos.software,
    imagem: imgSoftwares,
    novidade: 'não',
    iconeWhatsapp: 'sim'
  }
]