import imgTalentoNatoComercio from '../../components/imagens/talentonatocomercio.jpg';
import imgVagasvale from '../../components/imagens/vagasvale.jpg';
import imgUdemy from '../../components/imagens/udemy.jpg';
import imgShopee from '../../components/imagens/shopee.jpg';
import imgMultiplus from '../../components/imagens/multiplus.jpg';
import imgTutti from '../../components/imagens/tutti.jpg';
// import imgEmpregasaojose from '../../components/imagens/empregasaojose.jpg';
// import imgEmpregataubate from '../../components/imagens/empregataubate.jpg';

export const parceiros = [
  {
    titulo: "Talento Nato Comercio Store",
    descricao: "Sua Melhor Loja de Produtos! Conheça todas as novidades disponíveis pra você!",
    botao: "Acessar",
    rota: 'https://www.magazinevoce.com.br/magazinetalentonatocom/',
    imagem: imgTalentoNatoComercio,
    novidade: 'não',
    iconeWhatsapp: 'não',
  },
  {
    titulo: "Mimos da Tutti",
    descricao: "Artigos para Festas, embalagens e personalizados!",
    botao: "Acessar",
    rota: 'https://api.whatsapp.com/send?phone=5512991747633&text=Bem%20vindo%20a%20Tutti%20Festas%20e%20Embalagens!%20Como%20podemos%20ajudar%3F%20',
    imagem: imgTutti,
    novidade: 'sim',
    iconeWhatsapp: 'sim',
  },
  {
    titulo: "Multiplus",
    descricao: "Proteção Veicular contra:  Colisão, Furto, Incendio, Roubo, e Fenômenos da Natureza",
    botao: "Acessar",
    rota: 'https://api.whatsapp.com/send?phone=5512982881110&text=Gostaria%20de%20saber%20mais%20sobre%20este%20servi%C3%A7o!%20Cupom%3A%20%22Talento%20Nato%20Comercio%22',
    imagem: imgMultiplus,
    novidade: 'sim',
    iconeWhatsapp: 'sim',
  },
  {
    titulo: "Vagas Vale",
    descricao: "Vagas de empregos para toda região do Vale do Paraíba",
    botao: "Acessar",
    rota: 'https://www.vagasvale.com.br/',
    imagem: imgVagasvale,
    novidade: 'não',
    iconeWhatsapp: 'não',
  },
  {
    titulo: "Udemy",
    descricao: "Plataforma online para cursos de qualidade",
    botao: "Acessar",
    rota: 'https://www.udemy.com',
    imagem: imgUdemy,
    novidade: 'não',
    iconeWhatsapp: 'não',
  },
  {
    titulo: "Shopee",
    descricao: "Descubra a plataforma de compras on-line Shopee",
    botao: "Acessar",
    rota: 'https://shope.ee/4fK760amI4',
    imagem: imgShopee,
    novidade: 'sim',
    iconeWhatsapp: 'não',
  },





  
    // {
  //   titulo: "Emprega Taubaté",
  //   descricao: "Vagas de empregos para região de Taubaté",
  //   botao: "Acessar",
  //   rota: 'https://www.empregataubate.com.br',
  //   imagem: imgEmpregataubate,
  //   novidade: 'sim',
  //   iconeWhatsapp: 'não',
  // },
  // {
  //   titulo: "Emprega São José",
  //   descricao: "Vagas de empregos para região de São José dos Campos",
  //   botao: "Acessar",
  //   rota: 'https://www.empregasaojose.com.br',
  //   imagem: imgEmpregasaojose,
  //   novidade: 'sim',
  //   iconeWhatsapp: 'não',
  // },

]
