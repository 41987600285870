import { createStore } from "redux";
import { inicioBanco, inicioGoogle, status_inicial } from "./valor_inicio";


const reducer = (state = status_inicial, action) => {
  switch (action.type) {
    case "TROCA_COR":
      return {
        ...state,
        cor: action.cor,
      };
    case "TEMA":
      return {
        ...state,
        tema: action.tema,
      };
    case "USUARIO_GOOGLE":
      return {
        ...state,
        usuarioGoogle: action.usuarioGoogle,
        logado: true
      };
      case "USUARIO_BANCO":
        return {
          ...state,
          banco: action.banco
        }        
        case "TODOS_USUARIOS":
        return {
          ...state,
          usuariosBanco: action.usuariosBanco
        }  
        case "TODAS_TRANSACOES":
        return {
          ...state,
          listaTransacoes: action.listaTransacoes
        }    
        case "TODAS_NOTIFICACOES":
          return {
            ...state,
            listaNotificacao: action.listaNotificacao
          }  
      case "USUARIO_MASTER":
        return {
          ...state,
          master: action.master,
        };
      case "DESLOGA_GOOGLE":
        return {
          ...state,
          usuarioGoogle: inicioGoogle,
          logado: false,
          banco: inicioBanco
        };
        case "ALERTA":
          return {
            ...state,
            alerta: action.alerta,
          };
        case "LIMPA_MENSAGEM":
          return {
            ...state,
            alerta: action.alerta,
          };
          case "PAGINA_ALERTA":
            return {
              ...state,
              paginaAlerta: action.paginaAlerta,
            };
            case "ULTIMA_TRANSACAO":
              return {
                ...state,
                bancoTransacoes: action.bancoTransacoes,
              };
              case "NUMERO_NOTIFICACOES":
              return {
                ...state,
                numeroNotificacoes: action.numeroNotificacoes,
              };
              
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;