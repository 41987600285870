import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { novidades } from "./../../../dados/novidades/novidades";
import store from "../../../store/store";
import {
  DataAtualSegundosIso,
  MascaraDiferencaDias,
  MascaraDiferencaDiasBanco,
} from "../../../config/mascaras/mascaras";
import { AlertaMensagem, NumNotificacoes } from "../../../store/action";
import { DBleNotificacao } from "../../../config/login/valida";

export default class Lista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificacao: store.getState().banco.notificacao.seconds,
      listaNotificacao: store.getState().listaNotificacao,
    };
    store.subscribe(() => {
      this.setState({
        notificacao: store.getState().banco.notificacao.seconds,
        listaNotificacao: store.getState().listaNotificacao,
      });
    }, [store.getState().banco.notificacao.seconds]);
   
  }
  
  render() {
    function leNotificacao(){
      // DBleNotificacao(store.getState().usuarioGoogle.email)
      store.dispatch(AlertaMensagem("0019"));
      // console.log('Função desabilitada temporariamente');
    }
    return (
      <>
      <Box sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
        <List component="div" role="group">
     
                <Box>
                  <ListItem button divider aria-label={'Ler todas as notificações'} sx={{border: 1}} onClick={leNotificacao}>
                    <ListItemText 
                      primary={'Ler todas as notificações'}
                      secondary={'Clique para marcar todas as noticações como lidas'}
                    />
                  </ListItem>
                </Box>
        </List>
      </Box>
      <Box sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
      >
        <List component="div" role="group">
          {this.state.listaNotificacao.map((item, index) => {
            if (1) { //MascaraDiferencaDiasBanco( item.data.seconds, this.state.notificacao) > 0  
              return (
                <Box key={index} className='notfi'>
                  <ListItem button divider aria-label={item.titulo}>
                    <ListItemText
                      primary={item.titulo}
                      secondary={item.descricao}
                    />
                  </ListItem>
                </Box>
              );
            }
          })}
        </List>
      </Box>
      </>
    );
  }
}
