import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { balanco_mensal } from "../../../../dados/grafico/balanco_mensal";
import Titulo from "../titulo/titulo";
import { connect } from "react-redux";
import { ConverteDataBanco } from './../../../../config/mascaras/mascaras';

function Balanco({transacoes}) {
  const theme = useTheme();
  const data = transacoes.map((item, index) => {
    return {
      data: ConverteDataBanco(item.criacao.seconds),
      valor: (item.tipo != "saida")? (item.valor) : (item.valor),
    };
  });

  return (
    <React.Fragment>
      <Titulo>Balanço Mensal</Titulo>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="data"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Recebidos (R$)
            </Label>
          </YAxis>
          <Line
            isAnimationActive={true}
            type="monotone"
            dataKey="valor"
            stroke={theme.palette.primary.main}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

export default connect((state) => ({ transacoes: state.listaTransacoes }))(Balanco);
