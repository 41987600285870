import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import BotaoGoogle from "../../../config/login/botaoGoogle";
import { styled } from "@mui/material/styles";
import { Badge } from "@mui/material";
import { info_login } from "./../../../dados/login/info_login";
import { info_perfil } from "./../../../dados/login/info_perfil";
import Sociais from "../../geral/botoes_sociais";
import { Mascara_maiusculo } from "../../../config/mascaras/mascaras";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Login = ({ usuario, logado }) => {
  return (
    <div>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: info_perfil.foto.url,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {logado === true ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  src={usuario.foto}
                  sx={{
                    width: {
                      xs: info_perfil.foto.tamanho.xs,
                      sm: info_perfil.foto.tamanho.sm,
                      md: info_perfil.foto.tamanho.md,
                    },
                    height: {
                      xs: info_perfil.foto.tamanho.xs,
                      sm: info_perfil.foto.tamanho.sm,
                      md: info_perfil.foto.tamanho.md,
                    },
                  }}
                ></Avatar>
              </StyledBadge>
            ) : (
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
            )}
            <p />

            <Typography component="h1" variant="button">
              {logado === true ? info_perfil.saudacao : ""}
            </Typography>
            <Typography component="h1" variant="h6">
              {logado === false ? info_login.saudacao : ""}
            </Typography>
            <br />
            {logado === true ? (
              <div>
                <Typography component="h1" variant="subtitle1">
                  {Mascara_maiusculo(usuario.nome)}
                </Typography>
              </div>
            ) : (
              ""
            )}
            <p />

            {logado === true && usuario.telefone !== null ? (
              <div>
                <Typography component="h1" variant="h5">
                  {usuario.telefone}
                </Typography>
              </div>
            ) : (
              ""
            )}
            <br />
            {logado === true ? (
              <div>
                <Typography component="h1" variant="subtitle2">
                  {usuario.email}
                </Typography>
              </div>
            ) : (
              ""
            )}
            {logado === true ? (
              <div>
                <br />
                <Box>
                 <Sociais/>
                </Box>
              </div>
            ) : (
              ""
            )}

            <Box
              sx={{ mt: 1 }}
            >
              <Grid container>
                <BotaoGoogle />
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect((state) => ({
  usuario: state.usuarioGoogle,
  logado: state.logado,
}))(Login);
