
import  Typography  from '@mui/material/Typography';

import  Link  from '@mui/material/Link';
import { info } from './../../dados/info';
import { Box } from '@mui/material';


const Copyright = () => {
    return (
      <Box sx={{alignItems:'center', textAlign: 'center', mb: 5}}>
      <Typography variant="body2" color="primary.main" align="center" >
        {'Copyright © '}
        <Link color="primary.main" href={info[0].link} underline="none">
          {info[0].titulo}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </Box>
    );
  }
  
  export default Copyright;