import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import store from "../../store/store";
import { connect } from "react-redux";
import { ValorPaginaAlerta } from "../../store/action";

function DescricaoOrdens({ estadoAlerta, ...props }) {
  const handleClickOpen = (scrollType) => () => {
    store.dispatch(ValorPaginaAlerta({ estado: true }));
  };

  const handleClose = () => {
    store.dispatch(ValorPaginaAlerta({ estado: false }));
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (estadoAlerta) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [estadoAlerta]);

  return (
    <>
      <Button onClick={handleClickOpen()}>{props.botao}</Button>
      <Dialog
        open={estadoAlerta}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="pagina-alerta-titulo"
        aria-describedby="pagina-alerta-conteudo"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="pagina-alerta-titulo">{props.titulo}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText
            id="pagina-alerta-conteudo"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.conteudo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sair</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default connect((state) => ({
  estadoAlerta: state.paginaAlerta.estado,
}))(DescricaoOrdens);
