import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Titulo(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

Titulo.propTypes = {
  children: PropTypes.node,
};
