import {useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { status_inicial } from '../../store/valor_inicio';

function Notificacao({dados}) {
  const { enqueueSnackbar } = useSnackbar();

let mensagem = dados.alerta.mensagem;
let tipo = dados.alerta.tipo;
  useEffect(()=>{
      if(dados.alerta !== status_inicial.alerta){
        enqueueSnackbar(mensagem, {variant: tipo});
      }
  },[dados.alerta])

}

export default connect((state) => ({ dados: state }))(Notificacao);
