import { info } from "../info";

export const info_perfil = {
    titulo: "LOGIN",
    descricao: 'Faça seu Login',
    saudacao: 'Seja bem Vindo(a)',
    nome: "",
    email: "",
    telefone: "",
    nascimento: "",
    logadouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "",
    cep: "",
    botao: 'SAIR',
    fale_conosco: info[0].contato.link_whatsapp,
    perfil: info[0].contato.facebook,
    foto: {
      url: 'url(https://cdn.dribbble.com/users/2046015/screenshots/4397569/media/f6ddfe5906d54cc37d2d15bb5208b950.png)',
      variacao: "",
      tamanho: {
        xs: 100,
        sm: 150,
        md: 200,
      },
    },
  };
  
  
  