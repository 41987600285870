import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { connect } from "react-redux";
import {
  MascaraCelular,
  MascaraConverterData,
  MascaraDataAtual,
  MascaraDataHoraFormatada,
  MascaraDiferencaDias,
  TesteData,
} from "../../../../config/mascaras/mascaras";
import { Avatar } from "@mui/material";
import ConfigUsers from "./../../../geral/configUsers";
import { icone } from "./../../../icones/icones";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const logradouro = row.logradouro ? row.logradouro + ", " : "";
  const numero = row.numero ? row.numero + ", " : "";
  const complemento = row.complemento ? row.complemento + ", " : "";
  const bairro = row.bairro ? row.bairro + ", " : "";
  const cidade = row.cidade ? row.cidade + " - " : "";
  const estado =
    (row.estado != "") & (row.logradouro != "")
      ? row.estado
      : "Endereço não informado";
  const enderecoCompleto =
    logradouro + numero + complemento + bairro + cidade + estado;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Avatar alt={row.nome} src={row.foto} />
        </TableCell>
        <TableCell sx={{ typography: "body2" }} align="right">
          {row.apelido}
        </TableCell>
        <TableCell sx={{ typography: "body2" }} align="right">
          {row.nome}
        </TableCell>
        <TableCell sx={{ typography: "body2" }} align="right">
          {row.email}
        </TableCell>
        <TableCell sx={{ typography: "body2" }} align="right">
          {MascaraCelular(row.telefone)}
        </TableCell>
        <TableCell sx={{ typography: "body2" }} align="right">
          {row.logado ? icone.logado : icone.deslogado}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                MAIS INFORMAÇÕES
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ typography: "subtitle2" }} align="left">
                      ENDEREÇO
                    </TableCell>
                    <TableCell sx={{ typography: "subtitle2" }} align="left">
                      NASCIMENTO
                    </TableCell>
                    <TableCell sx={{ typography: "subtitle2" }} align="right">
                      ULTIMA MODIFICAÇÃO
                    </TableCell>
                    <TableCell sx={{ typography: "subtitle2" }} align="right">
                      PERMISSÕES
                    </TableCell>
                    <TableCell sx={{ typography: "subtitle2" }} align="right">
                      CRIAÇÃO
                    </TableCell>
                    <TableCell sx={{ typography: "subtitle2" }} align="right">
                      OPÇÕES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {enderecoCompleto}
                    </TableCell>
                    <TableCell>
                      {row.nascimento
                        ? MascaraConverterData(row.nascimento)
                        : "Nascimento Não Informado"}
                    </TableCell>
                    <TableCell align="right">
                      {MascaraDiferencaDias(row.modificacao.seconds)}
                    </TableCell>
                    <TableCell align="right">
                      {row.tipo ? icone.adminstrador : icone.usuario}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {MascaraDiferencaDias(row.criacao.seconds)}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <ConfigUsers email={row.email} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

function Usuarios({ usuarios }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell sx={{ typography: "h6" }} align="right">
              APELIDO
            </TableCell>
            <TableCell sx={{ typography: "h6" }} align="right">
              NOME
            </TableCell>
            <TableCell sx={{ typography: "h6" }} align="right">
              E-MAIL
            </TableCell>
            <TableCell sx={{ typography: "h6" }} align="right">
              TELEFONE
            </TableCell>
            <TableCell sx={{ typography: "h6" }} align="right">
              CONECTADO
            </TableCell>
            <TableCell sx={{ typography: "h6" }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {usuarios
            .filter((a) => a.tipo === true)
            .map((user, index) => (
              <Row key={index} row={user} />
            ))}
          {usuarios
            .filter((a) => a.logado === true && a.tipo === false)
            .map((user, index) => (
              <Row key={index} row={user} />
            ))}
          {usuarios
            .filter((a) => a.logado === false && a.tipo === false)
            .map((user, index) => (
              <Row key={index} row={user} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default connect((state) => ({ usuarios: state.usuariosBanco }))(
  Usuarios
);
