export const Contas = (conta) => {
  switch (conta) {
    case 0:
      return {
        id: 0,
        titulo: "CARTEIRA",
      };
    case 1:
      return {
        id: 1,
        titulo: "NUBANK PESSOAL",
      };
    case 2:
      return {
        id: 2,
        titulo: "NUBANK EMPRESARIAL",
      };
    case 3:
      return {
        id: 3,
        titulo: "BRADESCO",
      };
    case 4:
      return {
        id: 4,
        titulo: "CAIXA",
      };
    case 5:
      return {
        id: 5,
        titulo: "BANCO DO BRASIL",
      };
    case 6:
      return {
        id: 6,
        titulo: "PICPAY",
      };
    case 7:
      return {
        id: 7,
        titulo: "PAGBANK",
      };
    case 8:
      return {
        id: 8,
        titulo: "INTER PESSOAL",
      };
    case 9:
      return {
        id: 9,
        titulo: "MERCADO PAGO",
      };
    case 10:
      return {
        id: 10,
        titulo: "ITAÚ",
      };
    default:
      return "Conta Não Cadastrada";
  }
};
