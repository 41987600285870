import { Box } from '@mui/material';
import Partes from './grid';

const Cadastro = () => {
 
  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
   <Partes/>
    </Box>
  );
};

export default Cadastro;
