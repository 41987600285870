import Cores from "../cores";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import store from "./../../store/store";
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getFirestore, orderBy } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { DBmaster, DBslave } from "../login/valida";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { MascaraCpf, MascaraDataAtual, MascaraSomenteNumeros } from "../mascaras/mascaras";
import { log_priori, usuariosBancoInicio } from "../../store/valor_inicio";
import {
  AlertaMensagem,
  LogaGoogle,
  LogaMaster,
  SalvaUsuarios,
  SalvaTransacoes,
  UltimaTransacao,
  SalvaNotificacoes
} from "../../store/action";
import {
  doc,
  where,
  query,
  limit,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  onSnapshot,
  collection,
  getDocFromCache,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_STORE_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_STORE_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_STORE_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_STORE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_STORE_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_STORE_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_STORE_FIREBASE_MEASUREMENT_ID,
};
// DEFINIÇÕES DE CONFIGURAÇÕES
export const fire = initializeApp(firebaseConfig);
export const db = getFirestore(fire);
export const analytics = getAnalytics(fire);

// COLEÇÕES
const refBanco = collection(db, "usuario");
const refTransacoes = collection(db, "transacoes");
const refBancoServicos = collection(db, "servicos");
const refBancoNotificacao = collection(db, "notificacao");

// CRIA USUARIO NO BANCO
export const CriaDado = async (dados) => {
  let dataAtual = await MascaraDataAtual("completa", "");
  const data = {
    nome: dados.nome,
    email: dados.email,
    foto: dados.foto,
    chave: dados.chave,
    logado: true,
    tema: dados.tema,
    tipo: dados.tipo,
    cor: dados.cor,
    telefone: dados.telefone,
    apelido: dados.apelido,
    nascimento: dados.nascimento,
    senha: dados.senha,
    criacao: dataAtual,
    cep: "",
    logradouro: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "SP",
    modificacao: dataAtual,
    notificacao: dataAtual,
  };
  await setDoc(doc(refBanco, dados.email), data);
  console.log("Dados Criados!");
};

// CRIA DADOS DE SERVIÇOS
export const CriaDadoServicos = async (dados) => {
  let dataAtual = await MascaraDataAtual("completa", "");
  const data = {
    identificador: dados.identificador,
    titulo: dados.titulo,
    descricao: dados.descricao,
    botao: dados.botao,
    rota: dados.rota,
    imagem: dados.imagem,
    iconeWhatsapp: dados.iconeWhatsapp,
    criacao: dataAtual,
    modificacao: dataAtual,
  };
  await setDoc(doc(refBancoServicos, dados.identificador), data);
  console.log("Dados Criados!");
};

export const BuscaServicos = async () => {
  const DBservicos = await getDocs(collection(db, "servicos"));
  if (!DBservicos.empty) {
    DBservicos.forEach((doc) => {
      const servicos = {
        identificador: doc.data().identificador,
        titulo: doc.data().titulo,
        descricao: doc.data().descricao,
        botao: doc.data().botao,
        rota: doc.data().rota,
        imagem: doc.data().imagem,
        iconeWhatsapp: doc.data().iconeWhatsapp,
        modificacao: doc.data().modificacao,
        criacao: doc.data().criacao,
      };
      // console.log(servicos);
    });
  } else {
    // console.log("Nenhum serviço encontrado");
  }
};

// Busca usuario
export const BuscaDado = async (email) => {
  const filtroEmail = query(refBanco, where("email", "==", email));
  const dados = await getDocs(filtroEmail);
  if (!dados.empty) {
    dados.forEach(async (doc) => {
      store.dispatch({ type: "TEMA", tema: doc.data().tema });
      store.dispatch({
        type: "USUARIO_BANCO",
        banco: doc.data() });
      let Ccor = Cores(doc.data().cor);
      store.dispatch({
        type: "TROCA_COR",
        cor: Ccor,
      });
      return 1;
    })
  } else {
    return 0;
  }
  // console.log(store.getState().usuariosBanco)
};

// Recupera usuario
export const RecuperarUsuario = () => {
  if (!store.getState().logado) {
    const auth = getAuth(fire);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("recuperou o usuario");
        store.dispatch(
          LogaGoogle({
            nome: user.displayName,
            email: user.email,
            telefone: user.phoneNumber,
            id: user.uid,
            foto: user.photoURL,
            anonimo: user.isAnonymous,
            emailverificado: user.emailVerified,
            token: "",
          })
        );
        BuscaDado(user.email);
        const id_priori = log_priori.id;

        if (user.uid === id_priori) {
          store.dispatch(LogaMaster(true));
          DBmaster(user.email);
        } else {
          store.dispatch(LogaMaster(false));
          DBslave(user.email);
        }
   
      } else {
        // console.log('usuario não logado!');
      }
    });
  }
};

//ATUALIZA OS DADOS DO USUARIO
export const atualizaBanco = async (dado, id) => {
  const filtroUpdate = doc(refBanco, id);
  await updateDoc(filtroUpdate, dado);
};

//BUSCA USUARIOS
export const BuscaUsuarios = async () => {
  const todosUsuarios = await getDocs(refBanco);
  const listaUsuarios = todosUsuarios.docs.map((doc) => doc.data());
  store.dispatch(SalvaUsuarios(listaUsuarios));
  // console.log(listaUsuarios);
};

//BUSCA RECEBIDOS
export const BuscaTransacoes = async () => {
  const todasTransacoes = await getDocs(refTransacoes);
  const listaTransacoes = todasTransacoes.docs.map((doc) => doc.data());
  store.dispatch(SalvaTransacoes(listaTransacoes));
  // console.log(listaTransacoes);
};
//BUSCA NOTIFICAÇÕES
export const BuscaNotificacao = async () => {
  const todasNotificacoes = await getDocs(refBancoNotificacao);
  const listaNotificacao = todasNotificacoes.docs.map((doc) => doc.data());
  store.dispatch(SalvaNotificacoes(listaNotificacao));
  // console.log(listaNotificacao);
};

// CRIA DADOS DE TRANSACAO
export const CriaDadoTransacao = async (dados) => {
  await MaiorId("transacoes", "identificador");
  let valor = await store.getState().bancoTransacoes.contador;
  let incrementado = String(Number(valor)+1);
  // console.log(incrementado);
  let dataAtual = await MascaraDataAtual("completa", "");
  const usuario = store.getState().usuarioGoogle.email;
  const data = {
    titulo: dados.titulo,
    descricao: dados.descricao,
    local: dados.local,
    tipo: dados.tipo,
    metodo: dados.metodo,
    status: dados.status,
    valor: dados.valor,
    identificador: incrementado,
    ordem: incrementado,
    criacao: dataAtual,
    usuario,
  };
  await setDoc(doc(refTransacoes, incrementado), data);
  console.log("Dados Criados!");
  BuscaTransacoes();
};
export const MaiorId = async (tabela, campo) => {
  if (tabela === "transacoes") {
    const q = query(refTransacoes, orderBy(campo, "desc"), limit(1));
    const dados = await getDocs(q);
    if(!dados.empty){
      dados.forEach(async (doc) => {
        store.dispatch(UltimaTransacao({ contador: doc.data().identificador }));
      });
    }else{
      store.dispatch(UltimaTransacao({ contador: 0 }));
      // console.log("Valor zerado, sem registros!")
    }
  }
};

export const ExcluirDadoBanco = async (tabela, campo) => {
  await deleteDoc(doc(db, tabela, campo));
  console.log("Excluído com sucesso!!");
  BuscaTransacoes();
}
