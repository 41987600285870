import Pagina_padrao from "../../geral/modelo_pagina";
import Login from "./login";
import { connect } from 'react-redux';
import { info_login } from './../../../dados/login/info_login';
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';

const LoginIndex = ({ logado }) => {
  logEvent(analytics, "LOGIN");
  return (
    <div>
      <Pagina_padrao titulo={(logado === false) ? (info_login.titulo) : ("PERFIL")} subtitulo={(logado === false) ? (info_login.descricao) : ("Suas Informações")}>
        <Login />
      </Pagina_padrao>
    </div>
  );
};

export default connect((state) => ({logado: state.logado }))(LoginIndex);
