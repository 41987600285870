// NOMES REAIS DE CADA ERRO
export const tipo_sistema = [
  "error",
  "success",
  "warning",
  "info",
  "secondary",
  "primary",
  "gray",
];

// TEMPO DE TELA DE CADA ERRO
const tempo = {
  erro: "1000",
  sucesso: "1000",
  aviso: "3000",
  info: "3000",
};

// REFERENCIA AO TIPO DE ERRO
const referencia_tipo_sistema = (valor) => {
  switch (valor) {
    case "erro":
      return tipo_sistema[0];
    case "sucesso":
      return tipo_sistema[1];
    case "aviso":
      return tipo_sistema[2];
    case "info":
      return tipo_sistema[3];
  }
};

//TIPOS DE ERROS warning, error, info, success
export const Avisos = (code) => {
  switch (code) {
    case "0001":
      return {
        code: "0001",
        mensagem: "Sistema em manutenção",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0002":
      return {
        code: "0002",
        mensagem: "Sem conexão com a internet",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0003":
      return {
        code: "0003",
        mensagem: "Servidor fora do ar",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0004":
      return {
        code: "0004",
        mensagem: "Tempo de espera execido",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0005":
      return {
        code: "0005",
        tipo: "success",
        mensagem: "Você saiu com sucesso!",
        tipo: referencia_tipo_sistema("sucesso"),
        tempo: tempo.sucesso,
      };
    case "0006":
      return {
        code: "0006",
        mensagem: "Não foi possivel Sair, por favor tente novamente!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0007":
      return {
        code: "0007",
        mensagem: "Usuario Master logado com sucesso!",
        tipo: referencia_tipo_sistema("info"),
        tempo: tempo.sucesso,
      };
    case "0008":
      return {
        code: "0008",
        mensagem: "Usuario Google logado com sucesso!",
        tipo: referencia_tipo_sistema("sucesso"),
        tempo: tempo.sucesso,
      };
    case "0009":
      return {
        code: "0009",
        mensagem: "Janela de Login encerrada por usuario!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0010":
      return {
        code: "0010",
        mensagem:
          "Erro Interno do Servidor, por favor, aguarde alguns instantes e tente novamente!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0011":
      return {
        code: "0011",
        mensagem: "Senha inválida!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0012":
      return {
        code: "0012",
        mensagem:
          "Login cancelado por duplo click no botão, uma nova guia será aberta!",
        tipo: referencia_tipo_sistema("aviso"),
        tempo: tempo.aviso,
      };
    case "0013":
      return {
        code: "0013",
        mensagem: "Dados Atualizados!",
        tipo: referencia_tipo_sistema("sucesso"),
        tempo: tempo.sucesso,
      };
    case "0014":
      return {
        code: "0014",
        mensagem: "Erro ao Atualizar!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0015":
      return {
        code: "0015",
        mensagem: "Campo nome muito pequeno!",
        tipo: referencia_tipo_sistema("erro"),
        tempo: tempo.erro,
      };
    case "0016":
      return {
        code: "0016",
        mensagem: "Dados iguais ao valor atual",
        tipo: referencia_tipo_sistema("aviso"),
        tempo: tempo.aviso,
      };
    case "0017":
      return {
        code: "0017",
        mensagem: "Nome atualizado",
        tipo: referencia_tipo_sistema("sucesso"),
        tempo: tempo.sucesso,
      };
      case "0018":
        return {
          code: "0018",
          mensagem: "Campo apelido muito pequeno!",
          tipo: referencia_tipo_sistema("erro"),
          tempo: tempo.erro,
        };
        case "0019":
      return {
        code: "0019",
        mensagem: "Função desabilitada temporariamente!",
        tipo: referencia_tipo_sistema("aviso"),
        tempo: tempo.aviso,
      };
  }
};

// ERR_INTERNET_DISCONNECTED
