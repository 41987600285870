


export const info = [
    { 
        titulo: 'Talento Nato Comércio',
        subtitulo: 'Vendas e Suporte de T.I.',
        link: 'https://www.talentonatocomercio.com',
        login: 'https://www.talentonatocomercio.com/login',
        desenvolvedor: 'Stevie Kelvin',
        descricao: '',
        endereco: {
            logradouro: 'Rua Joaquim Carlos da Silva',
            numero: '432',
            complemento: '',
            bairro: 'Chácara São Manoel',
            cidade: 'Taubaté',
            estado: 'SP',
            cep: '12071-630',
            pais: 'Brasil',
        },
        contato: {
            telefone: '(12)99236-8880',
            whatsapp: '(12)99236-8880',
            email: 'talentonatocomercio@gmail.com',
            facebook: 'https://www.facebook.com/talentonatocomerciooficial',
            instagram: 'https://www.instagram.com/talentonatocomercio/',
            link_whatsapp: 'https://api.whatsapp.com/send?phone=5512992368880',
            youtube: '',
            ligar: 'tel:+5512992368880',
        },
        links: {
            servicos: {
                manutencao: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20Manuten%C3%A7%C3%A3o!',
                site: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20Cria%C3%A7%C3%A3o%20de%20Sites!',
                backup: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20Backup!',
                upgrade: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20Upgrade!',
                software: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20instala%C3%A7%C3%A3o%20de%20Softwares!',
                internet: 'https://api.whatsapp.com/send?phone=5512992368880&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20Internet%20Rural%20dispon%C3%ADvel%20para%20a%20regi%C3%A3o%20de%20Monteiro%20Lobato%20-%20SP%20e%20S%C3%A3o%20Francisco%20Xavier%20-%20SP!',
            },
            produtos: {
                internet: '',
            }, 
            externos: {
                magazine : '',
            }
        }
    }
]