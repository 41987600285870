import { isEmpty } from '@firebase/util';
const mes = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];
const semana = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

export function Mascara_mes(n) {
  let v = mes[n];
  return v;
}

export function Mascara_mes_por_escrito(v) {

 if(v){
  let f = v.substr(0, 10).split("/").reverse().join("-");
  var hoje = new Date(f);
  var dd = hoje.getDate()+1;
  var mm = hoje.getMonth()+1;
  var aaaa = hoje.getFullYear();
  var horas = hoje.getHours();
  var minutos = hoje.getMinutes();
  var segundos = hoje.getSeconds();
      if(dd<10){dd='0'+dd}
      if(mm<10){mm='0'+mm}
      if(horas<10){horas='0'+horas}
      if(minutos<10){minutos='0'+minutos}
      if(segundos<10){segundos='0'+segundos}
  return dd +' de '+ mes[hoje.getMonth()] +' de '+ aaaa +' as  '+ horas +':'+ minutos +':'+ segundos;
 }else{
  var hoje = new Date();
  var dd = hoje.getDate();
  var mm = hoje.getMonth()+1;
  var aaaa = hoje.getFullYear();
  var horas = hoje.getHours();
  var minutos = hoje.getMinutes();
  var segundos = hoje.getSeconds();
      if(dd<10){dd='0'+dd}
      if(mm<10){mm='0'+mm}
      if(horas<10){horas='0'+horas}
      if(minutos<10){minutos='0'+minutos}
      if(segundos<10){segundos='0'+segundos}
  return dd +' de '+ mes[hoje.getMonth()] +' de '+ aaaa +' as  '+ horas +':'+ minutos +':'+ segundos;
 }
}

export function Mascara_maiusculo(v) {
  let r = v.toUpperCase();
  return r;
}

export function MascaraConverterData(data) {
  let data_americana = data;
  let data_brasileira = data_americana.split("-").reverse().join("/");
  return data_brasileira;
}
export function MascaraDataInversa(data) {
  let data_brasileira = data;
  let data_americana = data_brasileira.split("/").reverse().join("-");
  return data_americana;
}

export function TesteData(a){
  let data = new Date(a*1000).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})
  return data
}
export function ConverteDataBanco(a){
  if(a){
    let data = new Date(a*1000);
    return data.toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 16)
  }else{
    let data = new Date();
    return data.toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 16)
  }
}



export function MascaraDataHoraFormatada(a) {
  var hoje = Date.parse(Date(a*1000).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}));
  var dd = hoje.getDate();
  var mm = hoje.getMonth()+1;
  var aaaa = hoje.getFullYear();
  var horas = hoje.getHours();
  var minutos = hoje.getMinutes();
  var segundos = hoje.getSeconds();
      if(dd<10){dd='0'+dd}
      if(mm<10){mm='0'+mm}
      if(horas<10){horas='0'+horas}
      if(minutos<10){minutos='0'+minutos}
      if(segundos<10){segundos='0'+segundos}
  return dd +'/'+ mm +'/'+ aaaa +' '+ horas +':'+ minutos +':'+ segundos;
}

export function MascaraDataAtual(a, F) {
  switch (a) {
    case "":
      var dataAtual = Date.now();
      var data = new Date(dataAtual);
      var dia = data.getDate().toString();
      var diaF = dia.length == 1 ? "0" + dia : dia;
      var mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
      var mesF = mes.length == 1 ? "0" + mes : mes;
      var anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    case "completa":
      var data = new Date();
      return data;
    case "ler":
      var data = Date(F);
      var dia = data.getDate().toString();
      var diaF = dia.length == 1 ? "0" + dia : dia;
      var mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
      var mesF = mes.length == 1 ? "0" + mes : mes;
      var anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
  }
  if (a === null) {
  }
  if (a === "completa") {
    var data = new Date();
    return data;
  }
}
export function MascaraSomenteNumeros(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
}
export function MascaraCpf(campo){
    let a = Number(campo)
  if (a.length === 8) {
    var v= a.replace(/[^\d\s-\/]/g, "");
    var v1 = v.replace(/^(d{5})(d)/, "$1-$2");
    return v1;
  } else {
    return a;
  }
}

export function MascaraCelular(v) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "Número não Informado!");
  }
  return r;
}

export function MascaraDiferencaDias(a){
const d1  = new Date(a*1000).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 10).split("/").reverse().join("-");
const d2  = new Date().toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 10).split("/").reverse().join("-");
const diffInMs   = new Date(d2) - new Date(d1)
const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
const v =  (diffInDays > 1 ) ? (diffInDays+ " Dias") : ((diffInDays < 1) ? "Hoje": (diffInDays+" Dia")) 
return v
}
export function MascaraDiferencaDiasBanco(a, b){
  const d1  = new Date(a*1000).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 10).split("/").reverse().join("-");
  const d2  = new Date(b*1000).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"}).substr(0, 10).split("/").reverse().join("-");
  const diffInMs   = new Date(d2) - new Date(d1)
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const v =  diffInDays; 
  return v
  }

export function MascaraReais(a){
  var atual = Number(a);
//com R$
return atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}
export function DataAtualSegundosIso(){
  let a = (new Date().getTime())/1000;
  return a
}