import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import store from "./../../../../store/store";
import { ValorPaginaAlerta } from "./../../../../store/action";
import { CriaDadoTransacao } from "../../../../config/api/firebase";
import { MaiorId } from "./../../../../config/api/firebase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isEmpty } from "@firebase/util";

export default function NovaOrdem() {
  const [produtoServico, setProduto] = React.useState("");
  const [descricao, setDescricao] = React.useState("");
  const [valor, setValor] = React.useState("");
  const [localTransacao, setLocal] = React.useState("");
  const [tipoTransacao, setTipo] = React.useState("");
  const [pago, setPago] = React.useState("");
  const [metodo, setMetodo] = React.useState("");
  const dados = {
    titulo: produtoServico,
    local: localTransacao,
    descricao,
    tipo: tipoTransacao,
    metodo: metodo,
    status: pago,
    valor: tipoTransacao === "ENTRADA" ? valor : 0 - valor,
  };

  const MudaProdutoServico = (event) => {
    setProduto(event.target.value);
  };
  const MudaDescricao = (event) => {
    setDescricao(event.target.value);
  };

  const MudaStatus = (event) => {
    setPago(event.target.value);
  };
  const MudaMetodo = (event) => {
    setMetodo(event.target.value);
  };
  const MudaTipoTransacao = (event) => {
    setTipo(event.target.value);
  };
  const MudaLocal = (event) => {
    setLocal(event.target.value);
  };

  function criar() {
    CriaDadoTransacao(dados);
    store.dispatch(ValorPaginaAlerta({ estado: false }));
  }

  return (
    <>
      <Divider>Nova Ordem</Divider>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "16ch" },
        }}
        onSubmit={criar}
        autoComplete="off"
      >
        <TextField
          id="produtoServico"
          onChange={MudaProdutoServico}
          label="Produto / Serviço"
          required
        />
        <TextField
          id="descricao"
          onChange={MudaDescricao}
          label="Descrição"
          required
        />
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Local de Transação
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={localTransacao}
            label="Local de Transação"
            onChange={MudaLocal}
            required
          >
            <MenuItem value={"0"}>CARTEIRA</MenuItem>
            <MenuItem value={"1"}>NUBANK PESSOAL</MenuItem>
            <MenuItem value={"2"}>NUBANK EMPRESARIAL</MenuItem>
            <MenuItem value={"3"}>BRADESCO</MenuItem>
            <MenuItem value={"4"}>CAIXA</MenuItem>
            <MenuItem value={"5"}>BANCO DO BRASIL</MenuItem>
            <MenuItem value={"6"}>PICPAY</MenuItem>
            <MenuItem value={"7"}>PAGBANK</MenuItem>
            <MenuItem value={"8"}>INTER PESSOAL</MenuItem>
            <MenuItem value={"9"}>MERCADO PAGO</MenuItem>
            <MenuItem value={"10"}>ITAÚ</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Tipo de Transação
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tipoTransacao}
            label="Tipo de Transação"
            onChange={MudaTipoTransacao}
            required
          >
            <MenuItem value={"ENTRADA"}>ENTRADA</MenuItem>
            <MenuItem value={"SAIDA"}>SAÍDA</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="valor"
          inputProps={{ maxLength: 10 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValor(event.target.value.replace(/[^\d\s-\/]/g, ""));
          }}
          label="Valor"
          value={valor}
          required
        />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Metodo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={metodo}
            label="Metodo"
            onChange={MudaMetodo}
            required
          >
            <MenuItem value={"PIX"}>PIX</MenuItem>
            <MenuItem value={"DINHEIRO"}>DINHEIRO</MenuItem>
            <MenuItem value={"CARTÃO DÉBITO"}>CARTÃO DEBITO</MenuItem>
            <MenuItem value={"CARTÃO CRÉDITO"}>CARTÃO CRÉDITO</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pago}
            label="Status"
            onChange={MudaStatus}
            required
          >
            <MenuItem value={1}>Concluído</MenuItem>
            <MenuItem value={2}>A Concluír</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          component={Button}
          type="submit"
          sx={{ m: 1, width: "20ch", height: "7ch" }}
        >
          Enviar
        </Button>
      </Box>
    </>
  );
}
