import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import TextField from "@mui/material/TextField";
import { useState, ChangeEvent } from "react";
import { connect, useDispatch } from "react-redux";
import { DBaltera } from "../../../../config/login/valida";
import { Avatar, Link } from "@mui/material";
import store from "../../../../store/store";
import { AlertaMensagem } from "../../../../store/action";
import state from "./../../login/login";
import { SalvaDadoBanco } from "./../../../../store/action";
import { BuscaDado } from "../../../../config/api/firebase";
const Parte1 = ({ dados, banco }) => {
  //VARIAVEL DO REDUX
  const dispatch = useDispatch();
  //VARIAVEL DE DADOS COMPLETOS
  const [usr, setUsr] = useState("");
  const [nome, setNome] = useState(banco.nome);
  const [apelido, setApelido] = useState(banco.apelido);
  const [telefone, setTelefone] = useState(banco.telefone);
  const [nascimento, setNascimento] = useState(banco.nascimento);
  const [cep, setCep] = useState(banco.cep);

  const [logradouro, setLogradouro] = useState(banco.logradouro);
  const [complemento, setComplemento] = useState(banco.complemento);
  const [numero, setNumero] = useState(banco.numero);
  const [cidade, setCidade] = useState(banco.cidade);
  const [estado, setEstado] = useState(banco.estado);
  const [bairro, setBairro] = useState(banco.bairro);

  const envia = (e) => {
    e.preventDefault();
    var uNome          = nome;
    var uApelido       = apelido;
    var uTelefone      = telefone;
    var uNascimento    = nascimento;
    var uCep           = cep;
    var uLogradouro    = logradouro;
    var uComplemento   = complemento;
    var uNumero        = numero;
    var uCidade        = cidade;
    var uEstado        = estado;
    var uBairro        = bairro;
    var bNome          = banco.nome;
    var bApelido       = banco.apelido;
    var bTelefone      = banco.telefone;
    var bNascimento    = banco.nascimento;
    var bCep           = banco.cep;
    var bLogradouro    = banco.logradouro;
    var bComplemento   = banco.complemento;
    var bNumero        = banco.numero;
    var bCidade        = banco.cidade;
    var bEstado        = banco.estado;
    var bBairro        = banco.bairro;

    if ((nome, apelido, telefone, nascimento, cep, logradouro, complemento, numero, cidade, estado)) {
      if (
        uCep         ===    bCep &&
        uNome        ===    bNome &&
        uCidade      ===    bCidade &&
        uNumero      ===    bNumero &&
        uEstado      ===    bEstado &&
        uApelido     ===    bApelido &&
        uTelefone    ===    bTelefone &&
        uNascimento  ===    bNascimento &&
        uLogradouro  ===    bLogradouro &&
        uComplemento ===    bComplemento &&
        uBairro      ===    bBairro
      ) {
        store.dispatch(AlertaMensagem("0016"));
      } else {
        DBaltera(banco.email, {
          nome:         nome,
          apelido:      apelido,
          telefone:     telefone,
          cep:          cep,
          nascimento:   nascimento,
          logradouro:   logradouro,
          complemento:  complemento,
          numero:       numero,
          cidade:       cidade,
          estado:       estado,
          bairro:       bairro
        });
        const usuarioBanco = BuscaDado(banco.email, dispatch);
        usuarioBanco.then((res) => {
          if (res !== 0) {
            console.log("Usuario econtrado!");
          } else {
            alert("não foi possivel recarregar os dados atualizados");
          }
        });
      }
    }
  };

  return (
    <Box
      sx={{ bgcolor: "secondary", alignItems: "center", p: 3 }}
      component="form"
      autoComplete="off"
      onSubmit={envia}
    >
     
      {/* <Album/> */}
      <Box sx={{ bgcolor: "secondary" }}>
        <TextField
          fullWidth
          id="nome"
          label="Nome"
          value={nome}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setNome(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle color="primary" />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          disabled
          fullWidth
          id="email"
          label="E-mail"
          value={banco.email}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="apelido"
          label="Apelido"
          value={apelido}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setApelido(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="telefone"
          label="Telefone"
          placeholder="Ex.: 12991234567"
          value={telefone}
          required={true}
          inputProps={{ maxLength: 11 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setTelefone(event.target.value.replace(/[^\d\s-\/]/g, ""));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="nascimento"
          type="date"
          required={true}
          value={nascimento === "" ? "2000-01-01" : nascimento}
          onChange={(e) => {
            setNascimento(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="cep"
          label="Cep"
          value={cep}
          required={true}
          placeholder="Ex.: 00000-000"
          inputProps={{ maxLength: 9 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setCep(event.target.value.replace(/[^\d\s-\/]/g, ""));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="logradouro"
          label="Endereço"
          value={logradouro}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setLogradouro(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="numero"
          label="Numero"
          value={numero}
          required={true}
          placeholder="Ex.: 000000"
          inputProps={{ maxLength: 6 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setNumero(event.target.value.replace(/[^\d\s-\/]/g, ""));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="complemento"
          label="Complemento"
          value={complemento}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setComplemento(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="bairro"
          label="Bairro"
          value={bairro}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setBairro(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="cidade"
          label="Cidade"
          value={cidade}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setCidade(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <TextField
          fullWidth
          id="estado"
          label="Estado"
          value={estado}
          required={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setEstado(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Button variant="outlined" size="large" type="submit">
          ATUALIZAR DADOS
        </Button>
      </Box>
    </Box>
  );
};
export default connect((state) => ({ dados: state, banco: state.banco }))(
  Parte1
);
