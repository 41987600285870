import Pagina_padrao from "../../geral/modelo_pagina";
import { Box } from "@mui/material";
import Informacoes from "./Informacoes";
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';


const InfoIndex = () => {
  logEvent(analytics, 'INFORMAÇÕES');
  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div>
        <Pagina_padrao
          titulo="Informações"
          subtitulo="Noticías e informações"
          alignItems="center"
        >
       <Informacoes />
        </Pagina_padrao>
      </div>
    </Box>
  );
};

export default InfoIndex;
