import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { DBdesloga } from '../../config/login/valida';
import { BuscaUsuarios } from '../../config/api/firebase';
import { icone } from '../icones/icones';
import { info } from './../../dados/info';

export default function ConfigUsers(props) {
    const usrClick = () =>{
        DBdesloga(props.email);
        alert("Usuario : "+props.email+" Deslogado!");
        BuscaUsuarios();
    }
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <IconButton color="primary" aria-label="Sair" onClick={usrClick} component="label">
      {icone.sair}
      </IconButton>
      <IconButton color="primary" aria-label="Sair" href={"mailto:"+props.email+"?subject=E-mail de contato do site "+info[0].titulo+"&body=Sua nova senha é: 1234"} component="button">
        {icone.email}
      </IconButton>
    </Stack>
  );
}