import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Titulo from '../titulo/titulo';
import { connect } from 'react-redux';
import { listaTransacoesInicio } from '../../../../store/valor_inicio';
import { BuscaTransacoes, ExcluirDadoBanco } from '../../../../config/api/firebase';
import { MascaraConverterData, TesteData } from '../../../../config/mascaras/mascaras';
import { MascaraReais, MascaraDataHoraFormatada, ConverteDataBanco } from './../../../../config/mascaras/mascaras';
import { FiltraBanco, FiltraID } from '../../../../config/func';
import { bancos } from './../../../../config/definicoes';
import DescricaoOrdens from './../../../geral/descricaoOrdens';
import NovaOrdem from './novaOrdem';
import { configTransacoes, Contas } from './../../../../dados/transacoes';
import { Button } from '@mui/material';
import { icone } from './../../../icones/icones';



function preventDefault(event) {
  event.preventDefault();
}
function Ordens({transacoes}) {
  if (transacoes === listaTransacoesInicio){
    BuscaTransacoes()
  }
  const entradas = transacoes.filter( a => a.tipo === 'entrada' )
  function Deleta(id){
    ExcluirDadoBanco("transacoes", id)
  }
  return (
    <React.Fragment>
      <Titulo>Ordens recentes</Titulo>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Produto / Serviço</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Local de Transação</TableCell>
            <TableCell>Metodo de Pagamento</TableCell>
            <TableCell>Tipo de transação</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Excluir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {transacoes.map((item,index) => (
            <TableRow key={item.criacao}>
              <TableCell align="left">{item.titulo}</TableCell>
              <TableCell>{item.identificador}</TableCell>
              <TableCell>{ConverteDataBanco(item.criacao.seconds)}</TableCell>
              <TableCell>{Contas(Number(item.local)).titulo}</TableCell>
              <TableCell>{item.metodo}</TableCell>
              <TableCell>{(item.tipo === "entrada")? ("Entrada") : ("Saída")}</TableCell>
              <TableCell align="right">{(item.valor)?(MascaraReais(item.valor)) : ('R$: 00,00')}</TableCell>
              <TableCell align="right"><Button onClick={()=>Deleta(item.identificador)}>{icone.remover}</Button></TableCell>
              

            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DescricaoOrdens titulo={'Ordens'} conteudo={<NovaOrdem/>} botao={"Ver Ordens"} />
    </React.Fragment>
  );
}
export default connect((state) => ({ transacoes: state.listaTransacoes }))(
  Ordens
);
