import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Badge from '@mui/material/Badge';
import { icone } from './../icones/icones';
import Link from '@mui/material/Link';
import { Box } from "@mui/system";
import { useTheme } from '@mui/material/styles';


export default function Card_padrao(props) {
  const theme = useTheme();
  const margem = 1;
  const margem_esquerda = {
          xs: 5, // theme.breakpoints.up('xs')
          sm: 2, // theme.breakpoints.up('sm')
          md: 2, // theme.breakpoints.up('md')
          lg: 2, // theme.breakpoints.up('lg')
          xl: 2, // theme.breakpoints.up('xl')
  }
  const tamanho_card_largura = {
      xs: '100%', // theme.breakpoints.up('xs')
      sm: '100%', // theme.breakpoints.up('sm')
      md: 350, // theme.breakpoints.up('md')
      lg: 350, // theme.breakpoints.up('lg')
      xl: 350, // theme.breakpoints.up('xl')
  };
  const tamanho_card_altura = {
      xs: '100%', // theme.breakpoints.up('xs')
      sm: '100%', // theme.breakpoints.up('sm')
      md: 530, // theme.breakpoints.up('md')
      lg: 530, // theme.breakpoints.up('lg')
      xl: 530, // theme.breakpoints.up('xl')
    }
    const tamanho_minimo_largura_card = {
          xs: 200, // theme.breakpoints.up('xs')
          sm: 200, // theme.breakpoints.up('sm')
          md: 200, // theme.breakpoints.up('md')
          lg: 250, // theme.breakpoints.up('lg')
          xl: 250, // theme.breakpoints.up('xl')
    }
    const altura_foto = {
      xs: '100%', // theme.breakpoints.up('xs')
      sm: '100%', // theme.breakpoints.up('sm')
      md: 500, // theme.breakpoints.up('md')
      lg: 600, // theme.breakpoints.up('lg')
      xl: 600, // theme.breakpoints.up('xl')
    }
    const largura_foto = {
          xs: '100%', // theme.breakpoints.up('xs')
          sm: '100%', // theme.breakpoints.up('sm')
          md: 350, // theme.breakpoints.up('md')
          lg: 350, // theme.breakpoints.up('lg')
          xl: 350, // theme.breakpoints.up('xl')
    }
    const max_altura_foto = {
      xs: 345, // theme.breakpoints.up('xs')
      sm: 345, // theme.breakpoints.up('sm')
      md: 300, // theme.breakpoints.up('md')
      lg: 300, // theme.breakpoints.up('lg')
      xl: 300, // theme.breakpoints.up('xl') 
    }
    const min_largura_foto = {
          xs: 200, // theme.breakpoints.up('xs')
          sm: 200, // theme.breakpoints.up('sm')
          md: 200, // theme.breakpoints.up('md')
          lg: 200, // theme.breakpoints.up('lg')
          xl: 200, // theme.breakpoints.up('xl')
    }
    const min_altura_foto = {
          xs: 200, // theme.breakpoints.up('xs')
          sm: 200, // theme.breakpoints.up('sm')
          md: 200, // theme.breakpoints.up('md')
          lg: 250, // theme.breakpoints.up('lg')
          xl: 250, // theme.breakpoints.up('xl')
    }
return (
  <Box
    className='box'
      sx={{
        // '&:hover':{filter: 'drop-shadow(0 0 0.75rem ${alpha(theme.palette.primary.main, 0.16)})'},
      boxShadow: 5, 
      m: margem,
      ml: margem_esquerda,
      width: tamanho_card_largura,
      height: tamanho_card_altura,
      minWidth: tamanho_minimo_largura_card,
      '.img': {
        boxShadow: 0, 
        height: altura_foto,
        width: largura_foto,
        maxHeight: max_altura_foto,
        minWidth: min_largura_foto,
        minHeight: min_altura_foto
      }
    }}
  >
      <Card>
        <CardActionArea component={Link} href={props.rota} target='_blank' >
          <CardMedia
            component="img"
            className="img"
            image={props.imagem}
            alt={props.titulo}
          />
          <CardContent>
            <Badge badgeContent='Novidade' color="primary" sx={{ visibility: (props.novidade === 'sim') ? 'visible' : 'hidden' }}>
              {icone.novidade}
            </Badge>
            <Typography gutterBottom variant="h5" component="div" color="primary">
              {props.titulo}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{
              height: {
                xs: '100%', // theme.breakpoints.up('xs')
                sm: 60, // theme.breakpoints.up('sm')
                md: 60, // theme.breakpoints.up('md')
                lg: 60, // theme.breakpoints.up('lg')
                xl: 60, // theme.breakpoints.up('xl')
              }
            }}>
              {props.descricao}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" component={Link} href={props.rota} target='_blank' sx={{width: '100%'}}>
          {props.botao} 
          <pre> </pre>
            <Box>
                   {(props.whatsapp === 'sim') ? (icone.whatsapp) : (icone.direita) }
            </Box>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
