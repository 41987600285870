import Pagina_padrao from "../../geral/modelo_pagina";
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Card_padrao from "../../geral/card_padrao";
import { parceiros } from "../../../dados/parceiros/parceiros";
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';

const Parceiros = () => {
  logEvent(analytics, "PARCEIROS");
  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <div>
        <Pagina_padrao titulo='Parceiros' subtitulo='Conheça nossos parceiros!' alignItems='center'>
          <div>
            <Grid container alignItems='center' spacing={0}>
              {parceiros.map((item, index) => (
                <Card_padrao key={index} whatsapp={item.iconeWhatsapp} botao={item.botao} novidade={item.novidade} rota={item.rota} titulo={item.titulo} descricao={item.descricao} imagem={item.imagem} />
              ))}
            </Grid>
          </div>
        </Pagina_padrao>
      </div>
    </Box>
  );
};

export default Parceiros;
