import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { icone } from '../icones/icones';
import { info } from '../../dados/info';

export default function Sociais() {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab color="secondary" href={info[0].contato.link_whatsapp} target='_blank' aria-label="Whatsapp">
        {icone.whatsapp}
      </Fab>
      <Fab color="secondary" href={info[0].contato.facebook} target='_blank' aria-label="Facebook">
      {icone.facebook}
      </Fab>
      <Fab color="secondary" href={info[0].contato.instagram} target='_blank' aria-label="Instagram">
      {icone.instagram}
      </Fab>
      <Fab color="secondary" href={info[0].contato.ligar} target='_blank' aria-label="Telefone">
      {icone.telefone}
      </Fab>
    </Box>
  );
}