import Pagina_padrao from "../../geral/modelo_pagina";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { servicos } from "./../../../dados/servicos/servicos";
import Card_padrao from "../../geral/card_padrao";
import { BuscaServicos } from "../../../config/api/firebase";
import { analytics } from './../../../config/api/firebase';
import { logEvent } from 'firebase/analytics';

const Inicio = () => {
  // const dbservicos = BuscaServicos();
  // dbservicos
  //   .then((data, err) => {
  //     console.log(data);
  //   });
  logEvent(analytics, "INICIO");

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div>
        <Pagina_padrao
          titulo=""
          subtitulo="Conheça todos os nossos Produtos e Serviços!"
          alignItems="center"
        >
          <div>
            <Grid container alignItems="center" spacing={0}>
              {servicos.map((item, index) => (
                <Card_padrao 
                  key={index}
                  botao={item.botao}
                  whatsapp={item.iconeWhatsapp}
                  novidade={item.novidade}
                  rota={item.rota}
                  titulo={item.titulo}
                  descricao={item.descricao}
                  imagem={item.imagem}
                />
              ))}
            </Grid>
          </div>
        </Pagina_padrao>
      </div>
    </Box>
  );
};

export default Inicio;
