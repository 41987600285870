import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Ordens from "./ordens/ordens";
import Usuarios from "./usuarios/usuarios";
import { logEvent } from "firebase/analytics";
import Balanco from "./balanco_mensal/balanco";
import Container from "@mui/material/Container";
import PaginaAlerta from "../../geral/descricaoOrdens";
import { analytics } from './../../../config/api/firebase';
import Transferencias from "./transferencias/transferencias";

logEvent(analytics, 'GRAFICOS');

const Grafico = () => {
  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Balanco />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Transferencias />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Ordens />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Usuarios />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Grafico;
