import { Box } from "@mui/system";
import { Routes, Route } from "react-router-dom";
import Rodape from "../geral/rodape";
import { item_priori, pagina } from "../corpo/corpo";

const B2B_conteudo = () => {
  return (
    <Box sx={{
      minWidth: 200,      
    }}>
    <div>
      <Routes>
        {pagina.map((item, index) => (
          <Route key={index} path={item.rota} element={item.pagina} />
        ))}
         {item_priori.map((item, index) => (
          <Route key={index} path={item.rota} element={item.pagina} />
        ))}
      </Routes>
      <br/>
    <Rodape/>
    </div>
    </Box>
  );
};

export default B2B_conteudo;
