import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Parte1 from "./grids/parte1";
import { Avatar } from "@mui/material";
import { connect } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const partes = [<Parte1 />];

const Partes = ({dados, banco}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          m: 5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          alt={banco.nome}
          src={banco.foto}
          sx={{ width: {xs: "100%", sm: "200px", md: "100px"}, height:{xs: "100%", sm: "200px", md: "200px"}, minWidth:{xs: "100px", sm: "200px", md: "200px"}, minHeight:{xs: "100px", sm: "200px", md: "200px"}}}
        />
      </Box>
      <Grid
        container
        spacing={{ xs: 1 }}
        columns={{
          xs: 3,
          sm: 3,
          md: 6,
          xl: 12,
        }}
      >
        {partes.map((item, index) => (
  
          <Grid item xs={12} sm={12} md={12} key={index}  height='auto'>
            <Item>{item}</Item>
          </Grid>
    
        ))}
      </Grid>
    </Box>
  );
};

export default connect((state) => ({ dados: state, banco: state.banco }))(
  Partes
);